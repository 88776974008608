import React, { Component } from 'react';
import QueryString from 'query-string';

class ActivateUser extends Component{
    constructor(props) {
        super(props);
        console.log("lmao?");
        console.log(props.params);
        this.state = {verifying: true, verified: false}
    }

    componentWillMount() {
        console.log("lmao");
        window.console.log(this.props.location.search);
        window.console.log(QueryString.parse(this.props.location.search));
        fetch('https://us-central1-thezoneapp.cloudfunctions.net/app/activate' + this.props.location.search, {
            method: "POST"
        })
          .then(response => {
              console.log(response);
              if (response.code === 200) {
                  this.setState({verified: true, verifying: false});
              } else {
                  this.setState({verified: false, verifying: false});
              }
          })
          .catch(err => console.log(err));
    }

    render() {
        const {verifying} = this.state;
        return (
            <div className="intro">
                {verifying ?
                  <div>Verifying {this.props.location.search}</div> :
                  <div>Status: {this.state.verified ? 'Verified' : 'Not Found'}</div>
                }
            </div>
          );
    }
}

export default ActivateUser;