import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ActivateUser from "./activateUser";
import { Fade } from "react-slideshow-image";

// import logo from "./logo.svg";
import logo from "./images/thezone.png";
import blue from "./images/blue.png";
import red from "./images/red.png";
import purple from "./images/purple.png";
import green from "./images/green.png";
import brown from "./images/brown.png";
import grey from "./images/grey.png";
import whiteLogo from "./images/logo-white.png";
import blackLogo from "./images/logo-black.png";

import { faApple, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// const emailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2}|com|nus|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\b/;

const fadeProperties = {
  duration: 2000,
  transitionDuration: 1000,
  infinite: true,
  arrows: false
};

const SlideShow = () => (
  <div className="slide-container">
    <Fade {...fadeProperties}>
      <img alt="" src={grey} className="App-fade" />
      <img alt="" src={green} className="App-fade" />
      <img alt="" src={blue} className="App-fade" />
      <img alt="" src={purple} className="App-fade" />
      <img alt="" src={red} className="App-fade" />
      <img alt="" src={brown} className="App-fade" />
    </Fade>
  </div>
);

class Home extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <div className="App-footer-bar">
            <div className="App-info-links">
              <a
                className="App-info-text"
                href="mailto:hello@getthezone.app"
                target="_top"
              >
                Contact
              </a>
              <a
                className="App-info-text"
                href="https://www.instagram.com/thezoneapp"
              >
                Instagram
              </a>
              <a className="App-info-text" href="https://twitter.com">
                Twitter
              </a>
            </div>
            <img src={whiteLogo} alt="" className="App-small-logo" />
            <div className="App-info-links">
              <a
                className="App-info-text"
                href="https://www.notion.so/jovin/The-Zone-Privacy-Policy-256579b73d8344beb1a6a87584a2a331"
              >
                Privacy
              </a>
              <a
                className="App-info-text"
                href="https://www.notion.so/jovin/The-Zone-Terms-of-Use-8b998a42c0184ecdbf417cfb0b0da29a"
              >
                Terms
              </a>
              <a
                className="App-info-text"
                href="https://www.notion.so/jovin/The-Zone-Press-Kit-adeebd9bec8e4f26a97a3dd5d824692b"
              >
                Press Kit
              </a>
            </div>
          </div>
          <div className="App-text-container">
            <img src={logo} alt="" className="App-logo" />
            <img src={blackLogo} alt="" className="App-logo-black" />

            <h3 className="App-slogan">
              A home for your thoughts, emotions & mental health.
            </h3>
            <div className="App-store-container">
              <a className="App-store-btn" href="https://apps.apple.com/sg/app/the-zone-guided-journal/id1463313888" target="_blank">
                <FontAwesomeIcon
                  className="App-fa-icon"
                  icon={faApple}
                  color="white"
                  size="1x"
                />
                <p className="App-small-txt">
                  Download on the <br />
                  <span className="App-big-txt">App Store</span>
                </p>
              </a>
              <a className="App-store-btn" href="https://play.google.com/store/apps/details?id=com.thezone.thezone" target="_blank">
                <FontAwesomeIcon
                  className="App-fa-icon"
                  icon={faGooglePlay}
                  color="white"
                  size="1x"
                />
                <p className="App-small-txt">
                  Get it on <br />
                  <span className="App-big-txt">Google Play</span>
                </p>
              </a>
            </div>
          </div>
          <div id="App-image">
            <img src={blue} alt="" className="App-blue-screen" />
          </div>
          <div id="App-image">
            <img src={red} alt="" className="App-red-screen" />
          </div>
          <div id="App-image">
            <img src={green} alt="" className="App-green-screen" />
          </div>
          <div id="App-image">
            <img src={purple} alt="" className="App-purple-screen" />
          </div>
          <SlideShow />
        </header>
      </div>
    );
  }
}
class App extends Component {
  render() {
    return (
      <Router>
        <main>
          <Route path="/" exact={true} component={Home} />
          <Route path="/activate" component={ActivateUser} />
        </main>
      </Router>
    );
  }
}

export default App;
